import { getTodaysDate } from '../../../../utils/date';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { refGenerator } from '../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { GRN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';
import { listMapper } from './table/config';

const title = 'Create GRN';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'GRN',
    path: `/${GRN}`,
    active: true,
  },
];

const summaryDetailsMapper = (element, distributorId = 0) => ({
  id: element.id || '',
  date: element.date || getTodaysDate(),
  grnDate: element.grnDate || getTodaysDate(),
  invoiceDate: element.invoiceDate || getTodaysDate(),
  invoiceNumber: element.invoiceNumber || '',
  ricNumber : element.ricNumber || '',
  Vendor: { id: element.Vendor ? element.Vendor.id : 0 },
  Distributor: { id: element.Distributor ? element.Distributor.id || 0 : distributorId },
  remarks: element.remarks || '',
  transporterName: element.transporterName || '',
  vehicleNumber: element.vehicleNumber || '',
  receiptNumber: element.receiptNumber || '',
  images: element.images || [],
  isBlocked: element.isBlocked || false,
});

const totalPriceMapper = (element) => ({
  billDiscount: element.billDiscount || 0,
  tradeDiscount: element.tradeDiscount || 0,
  subTotal: element.subTotal || 0,
  total: element.total || 0,
  taxAmount: element.taxAmount || 0,
  taxableAmount: element.taxableAmount || 0,
  exciseAmount: element.exciseAmount || 0,
});

const detailsFormConfig = {
  [FORM_CONFIG.MAPPER]: (element) => summaryDetailsMapper(element, element.details ? element.details.Distributor.id : 0),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPDATE} SKU`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['date', 'vendorId', 'distributorId', 'invoiceNumber', 'receiptImage', 'ricNumber', 'remarks']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const formMapper = (element, distributorId) => ({
  details: summaryDetailsMapper(element, distributorId),
  lines: element.lines ? element.lines : [],
  amount: totalPriceMapper(element.amount ? element.amount : {}),
  invoiceNumber: '',
});

const formattedSkuLines = (list) =>
  list.map((item) => {
    const { SKU, SkuBatch, id, ...modifiedItem } = item;

    return modifiedItem;
  });

const formattedSkuLinesInUpdate = (list) => list.map((item) => listMapper(item));

const getPayload = (data, type = EVENT_OPERATION.CREATE) => ({
  invoiceDate: data.details.invoiceDate,
  invoiceTime: data.details.invoiceTime,
  invoiceNumber: data.details.invoiceNumber,
  remarks: data.details.remarks,
  distributorId: data.details.Distributor.id,
  vendorId: data.details.Vendor.id,
  grnDate: data.details.grnDate,
  transporterName: data.details.transporterName,
  vehicleNumber: data.details.vehicleNumber,
  receiptNumber: data.details.receiptNumber,
  images: data.images,
  vendorInvoiceNumber: data.details.vendorInvoiceNumber,
  lines: EVENT_OPERATION.create ? formattedSkuLines(data.lines) : formattedSkuLinesInUpdate(data.lines),
  amount: {
    ...data.amount,
    billDiscount: Number(data.amount.billDiscount),
    tradeDiscount: Number(data.amount.tradeDiscount),
  },
});

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({ input: getPayload(data) }),
    responseName: 'createGrn',
    message: `GRN ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      input: getPayload(data, EVENT_OPERATION.UPDATE),
    }),
    responseName: 'updateGRN',
    message: `GRN ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.APPROVE]: {
    objectMapper: (data) => ({
      id: data.id,
      input:  data.details.ricNumber ? {
        ...getPayload(data, EVENT_OPERATION.APPROVE),
        ricNumber:  data.details.ricNumber,
      } : getPayload(data, EVENT_OPERATION.APPROVE),
    }),
    responseName: 'approveGRN',
    message: `GRN ${MESSAGE_EVENT_OPERATION.APPROVE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({ id: data.id }),
    responseName: 'cancelGRN',
    message: `GRN ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
};

export {
  crudSuccess, breadCrumb as breadCrumbConfig, title, detailsFormConfig, formMapper
};
